// Placeholder Styling
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// Trim Text
@mixin trim($args...) {
  display: -webkit-box !important;
  -webkit-line-clamp: $args;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Fit Content
@mixin fitContent {
  display: inline-block;
  width: intrinsic;
  width: -webkit-fit-content;
  width: -o-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

// Button Reset
@mixin buttonReset {
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
