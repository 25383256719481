@import "../../assets/styles/partials/theme";

.roadMap {
  width: 100%;
  max-width: 81%;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    max-width: 85.25%;
  }

  &__top {
    padding: 50px 0;

    @media only screen and (max-width: 768px) {
      padding: 40px 0;
    }
  }

  &__layout {
    padding-top: 60px;

    @media only screen and (max-width: 768px) {
      padding-top: 40px;
    }
  }
}

.card {
  width: 100%;
  max-width: 59.86%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  direction: ltr !important;
  @media only screen and (max-width: 768px) {
    align-items: center;
    max-width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 111px;
    }
  }

  &:nth-of-type(odd) {
    flex-direction: row-reverse;
    margin-right: auto;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin-right: unset;
    }

    .card__title {
      .horizontalLine {
        top: 50%;
        right: 100%;
        transform: translate(-3px, -50%);

        height: 2px;
        width: 47%;

        @media only screen and (max-width: 768px) {
          top: 100%;
          right: unset;
          left: 50%;
          transform: translate(-50%, 1px);
          width: 2px;
          height: 42px;
        }
      }
    }

    .card__text {
      margin-right: 15.155%;
      border-top-left-radius: 54px;

      @media only screen and (max-width: 768px) {
        margin-right: auto;
        margin-left: auto;
        border-top-left-radius: 8px;
      }
    }
  }

  &:nth-of-type(even) {
    flex-direction: row;
    margin-left: auto;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin-left: unset;
    }

    .card__title {
      .horizontalLine {
        top: 50%;
        left: 100%;
        transform: translate(3px, -50%);

        height: 2px;
        width: 47%;

        @media only screen and (max-width: 768px) {
          top: 100%;
          right: unset;
          left: 50%;
          transform: translate(-50%, 1px);
          width: 2px;
          height: 42px;
        }
      }
    }

    .card__text {
      margin-left: 15.155%;
      border-top-right-radius: 54px;

      @media only screen and (max-width: 768px) {
        margin-right: auto;
        margin-left: auto;
        border-top-right-radius: 8px;
      }
    }
  }

  &__title,
  &__text {
    padding: 25px 25px;
    border-radius: 10px;
    background: rgba(36, 36, 38, 0.5);
    border: 3px solid rgba($secondaryColor, 0.4);
    box-shadow: 0px 5px 37px rgba(115, 115, 115, 0.1);
    text-align: center;

    @media only screen and (max-width: 1024px) {
      width: 33.054%;
      padding: 15px 15px;
    }

    @media only screen and (max-width: 768px) {
      border-radius: 8px;
      border: 1px solid rgba($secondaryColor, 0.4);
    }
  }

  &__title {
    position: relative;
    width: 33.054%;

    font-size: 24px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--white);
    white-space: nowrap;

    @media only screen and (max-width: 1440px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 18px;
      margin-bottom: 43px;

      width: 100%;
      max-width: 187px;
    }

    &.notLast {
      &::before {
        display: block;
        content: "";

        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 110px);
        z-index: 1;

        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--secondaryColor);

        @media only screen and (max-width: 1024px) {
          transform: translate(-50%, 140px);
        }

        @media only screen and (max-width: 768px) {
          content: none;
        }
      }

      &::after {
        display: block;
        content: "";

        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 3px);
        z-index: 1;

        width: 2px;
        height: 110px;
        background-color: var(--secondaryColor);

        @media only screen and (max-width: 1024px) {
          height: 140px;
        }

        @media only screen and (max-width: 768px) {
          content: none;
        }
      }
    }

    .horizontalLine {
      display: block;
      position: absolute;
      z-index: 1;
      background-color: var(--secondaryColor);
    }
  }

  &__text {
    width: 51.789%;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 198px;
    font-size: 20px;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 1.4px;

    @media only screen and (max-width: 1440px) {
      font-size: 18px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 16px;

      width: 100%;
      max-width: 276px;
    }

    &.notLast {
      @media only screen and (max-width: 768px) {
        position: relative;

        &::before {
          display: block;
          content: "";

          position: absolute;
          top: 100%;
          left: 50%;
          transform: translate(-50%, 105px);
          z-index: 1;

          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: var(--secondaryColor);
        }

        &::after {
          display: block;
          content: "";

          position: absolute;
          top: 100%;
          left: 50%;
          transform: translate(-50%, 3px);
          z-index: 1;

          width: 2px;
          height: 110px;
          background-color: var(--secondaryColor);
        }
      }
    }
  }

  &.active {
    .card__title,
    .card__text {
      border: 3px solid rgba($primaryColor, 0.4);

      @media only screen and (max-width: 768px) {
        border: 1px solid rgba($primaryColor, 0.4);
      }
    }
  }
}

.rtl {
  transform: scaleX(-1);
}
