@import "../../assets/styles/partials/theme";

.accordion {
  padding: 60px 75px;
  border-radius: 10px;
  background-color: rgba($primaryTextColor, 0.5);
  border: 1px solid var(--secondaryColor);

  @media only screen and (max-width: 1600px) {
    padding: 25px 25px;
  }

  @media only screen and (max-width: 768px) {
    padding: 20px 20px;
    margin-bottom: 20px;
  }

  &--expanded {
    border: 1px solid var(--primaryColor);

    .accordion {
      &__top {
        padding-bottom: 33px;

        @media only screen and (max-width: 1600px) {
          padding-bottom: 20px;
        }
      }

      &__bottom {
        max-height: 2500px;
      }

      &__icon {
        transform: rotate(180deg);
      }
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: padding-bottom $transition ease;
  }

  &__bottom {
    padding-right: 80px;
    max-height: 0;
    overflow: hidden;
    transition: max-height $transition ease;
    font-size: 18px;
    line-height: 1.2em;
    font-family: "Barlow", sans-serif;
    letter-spacing: 1.4px;
    color: var(--white);
    
    @media only screen and (max-width: 1600px) {
      padding-right: 60px;
    }

    @media only screen and (max-width: 768px) {
      padding-right: 40px;
    }

    p {
      font-size: 22px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 1.4px;
    color: var(--white);

    @media only screen and (max-width: 1600px) {
      font-size: 18px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }

    & a {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: var(--primaryColor);
      transition: color $transition-short linear;

      &:active,
      &:focus,
      &:hover {
        outline: none;
        cursor: pointer;
        color: lighten($primaryColor, 20%);
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 18px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
    }
  }

  &__title {
    font-size: 30px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--white);

    @media only screen and (max-width: 1600px) {
      font-size: 24px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  &__icon {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    min-width: 20px;
    min-height: 20px;
    cursor: pointer;
    transition: transform $transition ease;

    @media only screen and (max-width: 1600px) {
      width: 24px;
      height: 24px;
    }

    @media only screen and (max-width: 768px) {
      width: 20px;
      height: 20px;
    }

    & svg {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-size: 22px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 1.4px;
    color: var(--white);

    @media only screen and (max-width: 1600px) {
      font-size: 18px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }

    &:not(:last-of-type) {
      margin-bottom: 18px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }
}
