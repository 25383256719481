@import "./assets/styles/main.scss";

body {
    cursor: auto !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 span {
  cursor: pointer;
  color: #f0ce0e;
}

// .ReactFlagsSelect-module_selectBtn__19wW7 span::after {
//   cursor: pointer;
//   border-top: 5px solid #b70d0d;
//   border-bottom: 5px solid #f0ce0e !important;
// }

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM:hover {
  background-color: #7a7974 !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
  border-top: 5px solid #f0ce0e !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-family: inherit;
  color: #4d4d4d;
  border: thin solid rgba(77, 77, 77, 0.3);
  border-radius: 4px;
  background: transparent;
  border: none !important;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
  right: 0;
  left: 0; 
  background-color: #1b1e22 !important;
  color: #f0ce0e !important;
  border-color: #f0ce0e !important;
  @media (max-width: 767px) {
    
  font-size: 14px !important;
  }
}

.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
  border-top: 0 !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f0ce0e !important;
}

// .language-switcher{
//     text-align: right;
//     margin-right: 15px;
// }

// .flag-icon {
//     width: 20px !important;
//     height: 20px !important; 
//   }

//   .flag-btn{
//     border: none;
//   }

  /* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .flag-btn {
    margin-right: 5px;
  }
  
  // .flag-icon {
  //   width: 16px;
  //   height: 16px;
  //   // margin-right: 3px;
  // }
  
  .language-text {
    font-size: 12px;
  }

  .language-switcher{
    text-align: right;
}
}