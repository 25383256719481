@import "../../assets/styles/partials/theme";
@import "../../assets/styles/partials/mixins";

.scrollToTopButton {
  // Reset
  @include buttonReset();

  position: fixed;
  bottom: 25px;
  right: 35px;
  z-index: 2000;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  color: var(--tertiaryColor);
  border-radius: 5px;
  border: 3px solid var(--tertiaryColor);
  background-color: var(--primaryTextColor);
  transition: color $transition-short linear,
    border-color $transition-short linear;

  @media only screen and (max-width: 1440px) {
    padding: 5px 5px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 3px 3px;
  }

  @media only screen and (max-width: 768px) {
    right: 20px;
    bottom: 20px;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
    cursor: pointer;
    color: var(--primaryColor);
    border-color: var(--primaryColor);
  }

  & i {
    position: relative;
    width: 33px;
    height: 33px;

    @media only screen and (max-width: 1440px) {
      width: 28px;
      height: 28px;
    }

    @media only screen and (max-width: 1024px) {
      width: 24px;
      height: 24px;
    }
  }

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;

    fill: currentColor;
    transition: all $transition-short linear;
  }
}
