@import "../../assets/styles/partials/theme";

.stakeTwoWin {
  width: 100%;
  padding: 50px 0;
  padding-bottom: 0;
  background-image: url("/assets/images/stake-2-win/stake-2-win-background.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  @media only screen and (max-width: 768px) {
    padding: 40px 0;
    padding-bottom: 0;
  }
}

.heroSection {
  padding: 0 9.5%;

  @media only screen and (max-width: 1024px) {
    padding: 0 max(20px, 7.375%);
  }

  &__top {
    position: relative;
    padding-bottom: 60px;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
      padding-bottom: 40px;
    }
  }

  &__image {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding: 0 0 calc(100% * 962 / 1695) 0;
    overflow: hidden;

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    &__wrapper {
      width: 49%;
      max-width: 420px;
      margin: 0 auto;

      @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 320px;
      }
    }
  }

  &__heading {
    position: relative;
    text-align: center;
    z-index: 1;

    &::after {
      content: "";
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 100%;
      height: 5px;
      background-color: var(--secondaryColor);
      z-index: -1;

      @media only screen and (max-width: 1600px) {
        height: 3px;
      }
    }

    h2 {
      position: relative;
      display: inline-block;
      margin: 0 auto;
      min-width: 475px;

      font-size: 50px;
      font-family: "Russo One", sans-serif;
      font-weight: 400;
      line-height: 1.2em;
      text-align: center;
      text-transform: capitalize;
      color: var(--primaryColor);
      padding: 8px 30px;

      text-align: center;
      border: 3px solid var(--primaryColor);
      border-radius: 25px;
      background-color: var(--primaryTextColor);
      background-color: #2d3034;
      z-index: 1;

      @media only screen and (max-width: 1600px) {
        font-size: 40px;
        border: 2px solid var(--primaryColor);
      }

      @media only screen and (max-width: 1440px) {
        font-size: 30px;
        min-width: unset;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 24px;
      }

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  &__text {
    padding: 35px 0;
    padding-bottom: 85px;
    margin: 0 auto;

    font-size: 25px;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    line-height: 2.36em;
    letter-spacing: 1.4px;
    text-align: center;
    color: var(--white);

    @media only screen and (max-width: 1600px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 1024px) {
      padding-bottom: 60px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 13px;
    }

    & p:not(:last-of-type) {
      margin-bottom: 20px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 12px;
      }
    }
  }
}
