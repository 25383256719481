@import "../../assets/styles/partials/theme";

.mediaAndPress {
  &__brandsSlider {
    position: relative;
    z-index: 1;
    overflow: hidden;

    margin-bottom: 50px;
    padding: 20px 0;
    border: none;
    border-top: 1px solid var(--secondaryColor);
    border-bottom: 1px solid var(--secondaryColor);

    @media only screen and (max-width: 1600px) {
      margin-bottom: 40px;
    }
  }

  &__top {
    padding: 50px 0;

    @media only screen and (max-width: 768px) {
      padding: 40px 0;
    }
  }

  &__bottom {
    padding-bottom: 150px;

    @media only screen and (max-width: 1600px) {
      padding-bottom: 80px;
    }
  }
}
