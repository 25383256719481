@import "../../assets/styles/partials/theme";

.footer {
  width: 100%;
  max-width: 1728px;
  background-color: var(--primaryTextColor);
  overflow: hidden;

  &__wrapper {
    width: 87.5%;
    margin: 0 auto;
  }

  &__mainArea {
    display: flex;
    justify-content: space-between;
    padding: 70px 0 40px 0;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: unset;
      padding: 35px 0 30px 0;
      text-align: center;
    }
  }

  &__left,
  &__right {
    flex: 1 1 50%;

    @media only screen and (max-width: 768px) {
      flex: 1 1 100%;
    }
  }

  &__left {
    @media only screen and (max-width: 1200px) {
      padding-right: 30px;
    }

    @media only screen and (max-width: 768px) {
      padding-right: 0;
    }
  }

  &__right {
    padding-left: 10%;
    width: 100%;

    @media only screen and (max-width: 1440px) {
      padding-left: 5%;
    }

    @media only screen and (max-width: 1200px) {
      padding-left: 0;
    }

    @media only screen and (max-width: 768px) {
      padding-top: 25px;
    }
  }

  &__logo {
    display: inline-block;
    width: 420px;
    margin-bottom: 30px;

    @media only screen and (max-width: 1600px) {
      width: 280px;
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 1024px) {
      width: 215px;
    }

    & figure {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0 0 calc(100% * 51 / 267) 0;
      overflow: hidden;

      & img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__address {
    font-size: 18px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: #ffffff;
    margin-bottom: 5px;

    @media only screen and (max-width: 1600px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 480px) {
      font-size: 8px;
    }
  }

  @media (max-width: 774px) {
    .mobileHidden {
      display: none;
      
    }
  }

  @media (min-width: 775px) {
    .desktopHidden {
      display: none !important;
    }
  }

  &__email {
    font-size: 20px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 1.4px;
    color: #ffffff;

    @media only screen and (max-width: 1600px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 12px;
    }

    @media only screen and (max-width: 480px) {
      font-size: 10px;
    }
  }

  &__socialLinks {
    margin-top: 48px;

    @media only screen and (max-width: 1600px) {
      margin-top: 30px;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 25px;
    }

    &__wrapper {
      margin: -20px -20px;

      @media only screen and (max-width: 1600px) {
        margin: -12px -12px;
      }

      @media only screen and (max-width: 1024px) {
        margin: -6px -6px;
      }

      & > * {
        padding: 20px 20px;

        @media only screen and (max-width: 1600px) {
          padding: 12px 12px;
        }

        @media only screen and (max-width: 1024px) {
          padding: 6px 6px;
        }
      }
    }

    @media (max-width: 768px) {
      .footer-title {
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    

    a {
      display: inline-block;
      text-decoration: none;

      position: relative;
      width: 24px;
      height: 0;
      padding: 0 0 24px 0;
      transition: filter $transition-short linear;

      @media only screen and (max-width: 1600px) {
        width: 20px;
        padding: 0 0 20px 0;
      }

      @media only screen and (max-width: 1024px) {
        width: 15px;
        padding: 0 0 15px 0;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      &:active,
      &:focus,
      &:hover {
        cursor: pointer;
        outline: none;
        color: var(--primaryColor);

        img {
          filter: opacity(0.4) drop-shadow(0 0 0 $primaryColor);
        }
      }
    }
  }

  &__divider {
    appearance: none;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;

    width: 100vw;
    margin: 0 calc(-1 * ((100vw - 100%) / 2));
    height: 2px;
    background-color: var(--secondaryColor);
  }

  &__bottom {
    padding: 40px 0;

    @media only screen and (max-width: 768px) {
      padding: 30px 0;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      font-family: "Barlow", sans-serif;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: var(--white);

      @media only screen and (max-width: 1600px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(156px, 1fr));
    gap: 45px;
    width: 100%;
    align-items: center;

    @media only screen and (max-width: 1600px) {
      grid-template-columns: repeat(auto-fit, minmax(139px, 1fr));
      gap: 25px;
    }

    @media only screen and (max-width: 1440px) {
      grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
      gap: 20px;
    }

    @media only screen and (max-width: 1024px) {
      grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 480px) {
      gap: 15px;
    }

    &__title {
      font-size: 19px;
      font-family: "Barlow", sans-serif;
      font-weight: 700;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: var(--white);
      white-space: nowrap;

      @media only screen and (max-width: 1600px) {
        font-size: 18px;
      }

      @media only screen and (max-width: 1440px) {
        font-size: 12px;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }

      @media only screen and (max-width: 480px) {
        font-size: 14px;
        // margin-right: -65px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      @media only screen and (max-width: 768px) {
        margin-top: 10px;
        display: grid;
        place-items: center;
        margin-left: 12px;
      }

      &.empty {
        margin-top: 0;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: "Barlow", sans-serif;
      font-weight: 400;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: var(--white);

      display: inline-flex;
      width: intrinsic;
      width: -o-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      cursor: pointer;

      @media only screen and (max-width: 1600px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 1440px) {
        font-size: 12px;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 10px;
      }

      @media only screen and (max-width: 480px) {
        font-size: 10px;
      }

      &.active .footer__nav__link,
      &:active .footer__nav__link,
      &:hover .footer__nav__link {
        outline: none;
        cursor: pointer;
        border-bottom: 2px solid var(--primaryColor);
      }

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }

    &__link {
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;
      white-space: nowrap;
      border-bottom: 2px solid transparent;
      transition: border-bottom $transition-short ease;
    }

    &__icon {
      position: relative;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 15px;
      margin-right: 10px;

      @media only screen and (max-width: 768px) {
        margin-left: 10px;
        width: 7px;
        height: 7px;
      }

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__cta {
    grid-column: 3 / span 1;
  }
}

@media (max-width: 768px) {
  .footer-title {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.footer-item {
  width: 92px;
}

@media only screen and (max-width: 768px) {

  .footer-item {
    width: 30px; 
  }
}