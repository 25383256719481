@import "./theme";

:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }

  @each $color, $value in $grays {
    --#{$color}: #{$value};
  }

  @each $color, $value in $socialMediaThemeColor {
    --#{$color}: #{$value};
  }
}
