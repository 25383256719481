@import "../../assets/styles/partials/theme";
@import "../../assets/styles/partials/mixins";

.ReactFlagsSelect-module_selectBtn__19wW7:after {
  border-top: 5px

solid  #f0ce0e !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0;
}
.ReactFlagsSelect-module_selectBtn {
  /* Add your custom styles here */
  color: #f0ce0e !important;
  border-color: #f0ce0e;
  border-radius: 30px;
}

.custom-flags-select .ReactFlagsSelect-module_selectBtn__19wW7 {
  color: #f0ce0e !important;
  border-color: #f0ce0e !important;
  border-radius: 30px !important;
}

.header {
  position: sticky;
  top: 0;
  padding: 30px 30px;
  background-color: transparent;
  z-index: 10;


  &.dark {
    background-color: var(--primaryTextColor);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  }

  @media only screen and (max-width: 1440px) {
    padding: 25px 25px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 20px 20px;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 820px) {
      flex-direction: column-reverse;
    }

    .logo-section {
      flex: 1; /* Push logo to the left */
    }

    .nav-section {
      flex: 2; /* Larger space for navigation */
      display: flex;
      justify-content: center; /* Center navigation */
    }
    
    .cta-section {
      flex: 1; /* Push CTA to the right */
    }
  }

  &__logo {
    @media only screen and (max-width: 600px) {
      justify-self: center;
      padding-right: 12px;
      margin-left: -50px;
    }

    & figure {
      position: relative;
      width: 200px;
      max-width: 100%;
      height: 0;
      padding: 0 0 calc(100% * 51 / 267) 0;
      overflow: hidden;

      @media only screen and (max-width: 1440px) {
        width: 180px;
      }

      & img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  @media (max-width: 767px) {
    .mobileHidden {
      display: none;
    }
  }

  &__navigation {
    /* Only Desktop */
    .navigationList {
      display: flex;
      align-items: center;
      // margin-left: 235px;
      // padding-right: 170px;
      padding-left: 0px;
      @media only screen and (min-width: 1300px) and (max-width: 1800px) {
       padding-right: 0px;
      }
      @media only screen and (max-width: 1400px) {
        padding-left: 0px;
      }
      // @media only screen and (max-width: 1800px) {
      //   padding-left: 300px;
      // }
      @media only screen and (max-width: 820px) {
        display: none;
      }

      & > *:not(:last-child) {
        margin-right: 25px;

        @media only screen and (max-width: 1440px) {
          margin-right: 15px;
        }

        @media only screen and (max-width: 1200px) {
          margin-right: 10px;
        }
      }

      &__item {
        position: relative;

        &:not(:first-of-type) {
          padding-left: 33px; // 25 +  8

          @media only screen and (max-width: 1440px) {
            padding-left: 23px; // 15 + 8
          }

          @media only screen and (max-width: 1200px) {
            padding-left: 18px; // 10 + 8
          }

          &::after {
            content: "";
            display: block;

            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--secondaryColor);
            margin-left: 10px;
            display: none;
          }
        }

        &__top {
          display: flex;
          align-items: center;

          font-size: 16px;
          font-family: "Barlow", sans-serif;
          font-weight: 600;
          line-height: 1.2em;
          color: var(--white);
          transition: color $transition-short linear;

          @media only screen and (max-width: 1440px) {
            font-size: 14px;
          }

          @media only screen and (max-width: 1200px) {
            font-size: 12px;
          }

          &:hover {
            outline: none;
            cursor: pointer;
            color: var(--primaryColor);
          }
        }

        &__name,
        &__name > * {
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;
          color: inherit;
          transition: color $transition-short linear;
        }

        &__icon {
          position: relative;
          display: inline-block;
          width: 10px;
          height: 6px;
          margin-left: 14px;
          margin-right: 5px;

          @media only screen and (max-width: 1440px) {
            // margin-left: 10px;
          }

          color: currentColor;
          fill: currentColor;
          stroke: currentColor;
          transition: color $transition-short linear,
            stroke $transition-short linear, fill $transition-short linear,
            transform $transition-short ease;

          &--rotate {
            transform: rotate(180deg);
          }

          & svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 100%;
            height: 100%;
            // margin-left: -5px;
          }
        }
      }

      &__dropdown {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        background-color: transparent;
        padding-top: 25px;
        z-index: 10;
        width: 190px;

        @media only screen and (max-width: 1440px) {
          width: 162px;
        }
        
        &.show {
          visibility: visible;
          opacity: 1;
        }

        &.hide {
          visibility: hidden;
          opacity: 0;
        }

        &.show .navigationList__dropdown__wrapper {
          transform: scale(1);
        }

        &.hide .navigationList__dropdown__wrapper {
          transform: scale(0);
        }

        &__wrapper {
          padding: 43px 20px;
          background-color: var(--primaryTextColor);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          transform-origin: top;
          transition: transform $transition ease;

          @media only screen and (max-width: 1600px) {
            padding: 35px 35px;
          }

          @media only screen and (max-width: 1440px) {
            padding: 25px 25px;
          }
        }

        &__list {
          & > *:not(:last-child) {
            margin-bottom: 27px;

            @media only screen and (max-width: 1600px) {
              margin-bottom: 20px;
            }
            @media only screen and (max-width: 1440px) {
              margin-bottom: 15px;
            }
          }
        }

        &__item {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: "Barlow", sans-serif;
          font-weight: 600;
          line-height: 1.2em;
          letter-spacing: 1.4px;
          color: #a8dadc; // not in theme

          display: inline-flex;
          width: intrinsic;
          width: -o-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          cursor: pointer;

          @media only screen and (max-width: 1600px) {
            font-size: 16px;
            width: 200px;
          }

          @media only screen and (max-width: 1440px) {
            font-size: 14px;
          }

          &.active .navigationList__dropdown__link,
          &:active .navigationList__dropdown__link,
          &:hover .navigationList__dropdown__link {
            border-bottom: 2px solid var(--primaryColor);
          }

          &.active .navigationList__dropdown__icon,
          &:active .navigationList__dropdown__icon,
          &:hover .navigationList__dropdown__icon {
            transform: scale(1);
          }
        }

        &__link {
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;
          letter-spacing: inherit;
          color: inherit;
          white-space: nowrap;
          border-bottom: 2px solid transparent;
          transition: border-bottom $transition-short ease;
          white-space: normal;
        }

        &__icon {
          position: relative;
          display: inline-block;
          width: 15px;
          height: 13.85px;
          margin-left: 16px;
          transform: scale(0);
          transition: transform $transition-short ease;
          margin-right: 5px;

          @media only screen and (max-width: 1600px) {
            width: 12px;
            height: 10.85px;
          }

          @media only screen and (max-width: 1440px) {
            width: 10px;
            height: 8.85px;
          }

          & > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &__cta {
    // Only Desktop
    @media only screen and (max-width: 820px) {
      display: none;
    }
  }
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
  /* Add your custom styles here */
  color: #f0ce0e;
  border-color: #f0ce0e;
  border-radius: 30px;
}

.noTransition {
  transition: none !important;
}

.custom-dropdown .flag-select__btn {
  background-color: #f0ce0e;
  border-color: #f0ce0e;
  border-radius: 30px;
}

@media (min-width: 1920px) {
  .language-switcher.desktop-only {
    margin-right: 20px !important;
  }
}

@media (max-width: 1920px) {
  .desktop-only {
    margin-right: 20px !important;
  }
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}

/* Modal (How to Buy) */
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  &__backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    width: 80%;
    max-width: calc(1728px * 0.8);
    margin: 0 auto;
  }

  &__video {
    width: 100%;

    &__wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0 0 calc(100% * 9 / 16) 0;

      & iframe {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}


/* Mobile Only Code Below */

.mobileNavigation {
  position: relative;
  display: none;

  @media only screen and (max-width: 820px) {
    display: inline-block;
  }

  &__button {
    // Reset
    @include buttonReset();

    position: relative;
    width: 20px;
    height: 20px;
    background-color: transparent;
    stroke: var(--primaryColor);
    cursor: pointer;
    margin-left: 10px;

    & svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 100%;
      height: 100%;
      stroke: inherit;

      & path {
        stroke: inherit;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.48);
    cursor: pointer;
    z-index: 10;
  }

  &__drawer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 11;

    width: 100%;
    max-width: 267px;
    background-color: var(--primaryTextColor);
    padding: 20px 20px;
    transition: transform $transition-long
      cubic-bezier(0.82, 0.085, 0.395, 0.895);

    @media only screen and (max-width: 380px) {
      width: 75%;
    }

    &.close {
      transform: translateX(-100%);
    }

    &.open {
      transform: translateX(0);
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > a {
      justify-self: center;
      margin: auto;
    }

    & > button {
      justify-self: flex-start;
      margin-right: auto;
      // margin-left: 245px;

      
    }
  }

  &__links {
    max-height: calc(100vh - 155px);
    overflow-y: auto;
    margin: 20px 0;

    &__item:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &__link {
      display: block;
      font-size: 20px;
      font-family: "Barlow", sans-serif;
      font-weight: 600;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: var(--white);
      transition: color $transition-short linear;
      @include trim(1);

      @media only screen and (max-width: 380px) {
        font-size: 16px;
      }

      &:hover {
        outline: none;
        cursor: pointer;
        color: var(--primaryColor);
      }
    }

    &__innerList {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s linear;

      &.active {
        padding-top: 10px;
        max-height: 500px;
      }
    }

    &__innerItem {
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }

    &__innerLink {
      display: block;
      font-size: 14px;
      font-family: "Barlow", sans-serif;
      font-weight: 400;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: var(--white);
      transition: color $transition-short linear;
      @include trim(1);

      @media only screen and (max-width: 380px) {
        font-size: 12px;
      }

      &:hover {
        outline: none;
        cursor: pointer;
        color: var(--primaryColor);
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__icon {
      position: relative;
      display: inline-block;
      width: 8px;
      height: 5px;
      margin-left: 14px;
      

      @media only screen and (max-width: 1440px) {
        margin-left: 10px;
      }

      color: currentColor;
      fill: currentColor;
      stroke: currentColor;
      transition: color $transition-short linear,
        stroke $transition-short linear, fill $transition-short linear,
        transform $transition-short ease;

      &--rotate {
        transform: rotate(180deg);
      }

      & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 100%;
        height: 100%;
      }
    }
    
  }
  

}
.customFormControlLabel {
  margin-left: 0;
  margin-right: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}




@media (max-width: 768px) {

}


