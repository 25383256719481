@import "../../assets/styles/partials/theme";
@import "../../assets/styles/partials/mixins";

.mediaCardsSlider {
  padding: 0 max(20px, 5%);
}

.card {
  width: 100%;
  max-width: 779px;

  &__wrapper {
    padding-bottom: 32px;

    border-bottom: 5px solid var(--primaryColor);
  }

  &__logo {
    position: relative;

    &::after {
      display: block;
      content: "";

      width: 100%;
      height: 2px;
      background-color: var(--white);

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: -1;
    }

    &__wrapper {
      width: 33.633%;
      max-width: 262px;
      padding: 12px 15px;
      border-radius: 100px;
      border: 2px solid var(--white);
      background-color: var(--white);
      margin-bottom: 38px;

      @media only screen and (max-width: 1600px) {
        margin-bottom: 25px;
      }

      @media only screen and (max-width: 1440px) {
        max-width: 220px;
      }

      @media only screen and (max-width: 768px) {
        max-width: 150px;
        padding: 5px 15px;
        margin-bottom: 20px;
      }

      & figure {
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 calc(100% * 63 / 206) 0;
        overflow: hidden;

        & img {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }

  &__date {
    font-size: 24px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 1.4px;
    color: var(--white);
    margin-bottom: 29px;

    @media only screen and (max-width: 1600px) {
      font-size: 20px;
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  &__title {
    font-size: 30px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--primaryColor);
    margin-bottom: 33px;
    @include trim(2);
    min-height: calc(1.2em * 2);

    @media only screen and (max-width: 1600px) {
      font-size: 24px;
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 15px;
    }

    & a {
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
    }
  }

  &__image {
    width: 100%;
    max-width: 779px;

    & figure {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0 0 100% 0;
      overflow: hidden;

      & img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
