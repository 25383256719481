@import "../../../assets/styles/partials/theme";
@import "../../../assets/styles/partials/mixins";

.card {
  width: 100%;
  // max-width: 445px;
  margin: 0 auto;

  &__wrapper {
    text-align: center;
    padding: 45px 45px;
    border-radius: 10px;
    background-color: var(--primaryTextColor);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    border: 2px solid transparent;
    transition: border $transition-short linear,
      box-shadow $transition-short linear, transform $transition-short linear;

    @media only screen and (max-width: 1600px) {
      padding: 35px 35px;
    }

    @media only screen and (max-width: 1440px) {
      padding: 25px 25px;
    }

    @media only screen and (max-width: 768px) {
      padding: 50px 50px;
    }

    @media only screen and (max-width: 480px) {
      padding: 35px 0px;
      // margin-left: -22px;
    }

    &:hover {
      outline: none;
      border: 2px solid rgba($white, 0.2);
      box-shadow: 0 0 30px rgba($white, 0.2);
      transform: scale(1.03);
    }
  }

  //   &__top {
  //   }

  &__middle {
    padding: 12px 10px;
    background-color: #202022; // Out of theme
    border-radius: 8px;
  }

  &__bottom {
    margin-top: 47px;

    & > button {
      @include buttonReset();
      background-color: transparent;

      font-size: 18px;
      font-family: "Russo One", sans-serif;
      font-weight: 400;
      line-height: 1.2em;
      color: var(--primaryColor);
      text-align: center;
      padding: 18px 18px;
      min-width: 190px;

      border-radius: 11px;
      border: 3px solid;
      border-color: var(--primaryColor);
      box-shadow: 0 0 40px transparent;
      transition: color $transition-short linear,
        background-color $transition-short linear,
        box-shadow $transition-short linear;

      @media only screen and (max-width: 1600px) {
        font-size: 16px;
        padding: 12px 12px;
        min-width: 175px;
        border-width: 2px;
      }

      @media only screen and (max-width: 768px) {
        font-size: 12px;
        min-width: 126px;
      }

      &:hover {
        outline: none;
        color: var(--white);
        background-color: var(--primaryColor);
        box-shadow: 0 0 40px rgba($white, 0.2);
      }
    }
  }

  &__image {
    width: 189px;
    margin: auto;

    @media only screen and (max-width: 1600px) {
      width: 140px;
    }
    @media only screen and (max-width: 1440px) {
      width: 125px;
    }

    figure {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0 0 100% 0;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__title {
    font-size: 24px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--white);
    letter-spacing: 1.4px;
    text-align: center;
    padding: 17px 0 37px 0;

    @media only screen and (max-width: 1600px) {
      padding: 32px 0;
    }
  }

  &__subTitle {
    font-size: 10px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--white);
    text-align: center;
    min-height: calc(12px * 1.2);
    margin-top: 1em;

    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media only screen and (max-width: 1600px) {
      font-size: 10px;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 8px;
      font-family: "Barlow", sans-serif;
      letter-spacing: 1px;
    }

    @media only screen and (max-width: 1200px) {
      font-size: 6px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 10px;
    }


    @media only screen and (max-width: 768px) {
      // display: none;
    }
  }

  &__attributes {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1400px) and (min-width: 769px) {
      flex-direction: column;
    }

    & > *:not(:last-child) {
      position: relative;
      padding-right: 2px;

      @media only screen and (max-width: 1400px) and (min-width: 769px) {
        padding-right: 0;
        padding-bottom: 2px;
      }

      &::after {
        content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    right: 100%;
    width: 2px;
    height: 100%;
    background-color: #242629;

        @media only screen and (max-width: 1400px) and (min-width: 769px) {
          top: unset;
          bottom: -22px;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
        }
      }
    }

    &__item {
      flex: 1;
      margin: 22px 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 1400px) and (min-width: 769px) {
        width: 100%;
      }
    }

    &__name {
      font-size: 14px;
      font-family: "Barlow", sans-serif;
      font-weight: 400;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: #718a90; // out of theme
      text-align: center;
      margin-bottom: 19px;

      @media only screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }

    &__value {
      font-size: 24px;
      font-family: "Barlow", sans-serif;
      font-weight: 600;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: var(--white);
      text-align: center;

      @media only screen and (max-width: 1440px) {
        font-size: 20px;
      }
    }
  }

  &__lastUpdated {
    padding-top: 18px;

    & p {
      font-size: 11px;
      font-family: 'Barlow', sans-serif;
      font-weight: 600;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: var(--white);
      text-align: center;
    }
  }
}
