@import "../../assets/styles/partials/theme";

.faq {
  width: 100%;
  max-width: 81%;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    max-width: 85.25%;
  }

  &__top {
    padding: 50px 0;

    @media only screen and (max-width: 768px) {
      padding: 40px 0;
    }
  }

  &__layout {
    padding-top: 60px;

    @media only screen and (max-width: 768px) {
      padding-top: 40px;
    }
  }

  &__accordionWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    @media only screen and (max-width: 1600px) {
      gap: 25px;
    }
    @media only screen and (max-width: 768px) {
      gap: 20px;
      display: block;
    }
  }
}
