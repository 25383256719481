@import "../../assets/styles/partials/theme";

.card {
  height: 100%;
  padding: 10px 10px;

  &__wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;

    background-color: var(--bodyColor);
    box-shadow: 2px 2px 2px rgba(#fff, 0.5);
    border-radius: 12px;
    padding: 20px 20px;
    margin: -10px -10px;

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
    }

    @media only screen and (max-width: 650px) {
      flex-direction: row;
      justify-content: flex-start;
    }

    @media only screen and (max-width: 420px) {
      flex-direction: column;
      justify-content: center;
      padding: 20px 10px;
    }

    & > * {
      padding: 10px 10px;
    }
  }

  &__avatar {
    display: inline-block;
    width: 128px;

    @media only screen and (max-width: 1024px) {
      width: 90px;
    }

    figure {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0 0 100% 0;
      border-radius: 50%;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__name {
    font-size: 20px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--white);
    margin-bottom: 15px;
  }

  &__text {
    p {
      font-size: 16px;
      font-family: "Barlow", sans-serif;
      font-weight: 500;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      // text-align: left;
      color: var(--white);

      &:not(:last-of-type) {
        margin-bottom: 3px;
      }
    }
  }

  &__linkedin {
    display: inline-block;
    width: 35px;
    margin-top: 15px;

    figure {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0 0 100% 0;
      border-radius: 0;
      overflow: hidden;
      filter: grayscale(1);
      transition: filter var(--transition) linear;

      &:hover {
        filter: grayscale(0);
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
