@import "../../assets/styles/partials/theme";
@import "../../assets/styles/partials/mixins";

.team {
  width: 100%;
  max-width: 74.77%;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    max-width: 85.25%;
  }

  // &__top {
  //   padding-bottom: 50px;

  //   @media only screen and (max-width: 768px) {
  //     padding-bottom: 40px;
  //   }
  // }

  &__top,
  &__bottom {
    padding: 50px 0 80px 0;

    @media only screen and (max-width: 1600px) {
      padding: 40px 0 80px 0;
    }

    @media only screen and (max-width: 768px) {
      padding: 40px 0 70px 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: max(250px, 24.15%) 1fr;
    gap: 114px;

    @media only screen and (max-width: 1600px) {
      gap: 80px;
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }

    &__left {
      padding-top: 35px;
      padding-right: 35px;
      padding-bottom: 28px;

      @media only screen and (max-width: 1600px) {
        padding-top: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
      }
    }
  }

  &__title {
    display: flex;
    margin-bottom: 30px;

    @media only screen and (max-width: 1600px) {
      align-items: center;
    }

    @media only screen and (max-width: 1440px) {
      margin-bottom: 20px;
    }
  }

  &__linkedin {
    display: inline-block;
    width: 55px;
    margin-left: 20px;

    @media only screen and (max-width: 768px) {
      width: 45px;
    }

    figure {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0 0 100% 0;
      border-radius: 0;
      overflow: hidden;
      filter: grayscale(1);
      transition: filter var(--transition) linear;

      &:hover {
        filter: grayscale(0);
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__image {
    @media only screen and (max-width: 768px) {
      max-width: 312px;
      margin: auto;
    }

    figure {
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      padding: 0 0 calc(100% / 276 * 418) 0;

      img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 2;
      }

      &::before {
        display: block;
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        transform: translate(35px, -35px);
        z-index: 1;

        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);

        @media only screen and (max-width: 1600px) {
          transform: translate(25px, -25px);
        }
      }

      &::after {
        display: block;
        content: "";

        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(0, 28px);
        z-index: 3;

        width: 94px;
        height: 101px;
        background-image: url("/assets/images/bath-floor.png");
        background-size: contain;
        background-position: bottom left;
        background-repeat: no-repeat;

        @media only screen and (max-width: 1600px) {
          transform: translate(0, 25px);

          width: 75px;
          height: 80px;
        }
      }
    }
  }

  &__text {
    font-size: 20px;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    line-height: 2.375em;
    letter-spacing: 1.4px;
    text-align: left !important;
    color: var(--white);

    @media only screen and (max-width: 1600px) {
      font-size: 18px;
      line-height: 1.6em;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }

    // &:not(:last-of-type) {
      margin-bottom: 20px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 12px;
        text-align: start !important;
      }
    }
  }
// }

.videoPlayer {
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0 0 calc(100% * 523 / 1292) 0;
    overflow: hidden;

    @media only screen and (max-width: 768px) {
      padding: 0 0 calc(100% / 16 * 9) 0;
    }

    & iframe,
    & video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      background-color: rgba(6, 1, 1, 0.78);
      z-index: 1;
    }
  }
}

.ourTeam {
  padding: 50px 0 80px 0;

  @media only screen and (max-width: 1600px) {
    padding: 40px 0 80px 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 40px 0 70px 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px 60px;
    padding-top: 60px;

    @media only screen and (max-width: 1600px) {
      gap: 35px 45px;
    }

    @media only screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px 25px;
    }
  }
}
