@import "../../assets/styles/partials/theme";

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($white, 0.4);
  }
  70% {
    box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse-hover {
  0% {
    box-shadow: 0 0 0 0 rgba($primaryColor, 0.4);
  }
  70% {
    box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.mediaAndPress {
  &__brandsSlider {
    position: relative;
    z-index: 1;
    overflow: hidden;

    margin-bottom: 50px;
    padding: 20px 0;
    border: none;
    border-top: 1px solid var(--secondaryColor);
    border-bottom: 1px solid var(--secondaryColor);

    @media only screen and (max-width: 1600px) {
      margin-bottom: 40px;
    }
  }

  &__top {
    padding: 50px 0;

    @media only screen and (max-width: 768px) {
      padding: 40px 0;
    }
  }

  &__bottom {
    padding-bottom: 150px;

    @media only screen and (max-width: 1600px) {
      padding-bottom: 80px;
    }
  }
}

.heroSection,
.videoSection {
  width: 90.25%;
  max-width: 1472px;
  margin: 0 auto;
  padding: 50px 0 50px 0;

  @media only screen and (max-width: 768px) {
    padding: 40px 0;
  }

  &__heading {
    position: static;

    &--1 {
      position: static;
    }

    &--2 {
      position: static;
    }
  }

  &__cardsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 65px;
    padding: 60px 0;

    @media only screen and (max-width: 1600px) {
      gap: 50px;
    }

    @media only screen and (max-width: 1440px) {
      gap: 30px;
    }

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 15px;
      padding: 20px 0 50px 0;
    }

    @media only screen and (max-width: 768px) {
      gap: 25px;
    }
  }

  &__sectionScroller {
    text-align: center;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &__icon {
      position: relative;
      display: block;
      width: 28px;
      height: 28px;
      margin: 0 auto;
      fill: var(--white);
      border-radius: 50%;
      animation: pulse 2s infinite;
      transition: fill $transition-short linear,
        transform $transition-short linear;

      &:hover {
        outline: none;
        cursor: pointer;
        fill: var(--primaryColor);
        transform: scale(1.08);
        animation: pulse-hover 2s infinite;
      }

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: inherit;

        & > * {
          fill: inherit;
        }
      }
    }
  }
}

.videoSection {
  padding-bottom: 80px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 40px;
  }

  &__video {
    width: 100%;

    &__wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding: 0 0 calc(100% * 9 / 16) 0;

      & iframe {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
