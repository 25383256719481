@import "../../assets/styles/partials/theme";
@import "../../assets/styles/partials/mixins";

.aboutUs {
  width: 100%;
  max-width: 81%;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    max-width: 85.25%;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0 20px;
  }

  &__top {
    padding: 50px 0;

    @media only screen and (max-width: 768px) {
      padding: 40px 0;
    }
  }

  &__layout {
    padding-top: 60px;

    @media only screen and (max-width: 768px) {
      padding-top: 40px;
    }
  }

  &__cardsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 80px;

    @media only screen and (max-width: 1600px) {
      gap: 60px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

.card1 {
  padding: 28px 60px;
  border-radius: 10px;
  background-color: rgba($primaryTextColor, 0.8);
  border: 1px solid var(--secondaryColor);
  backdrop-filter: blur(15px);

  @media only screen and (max-width: 1600px) {
    padding: 30px 30px;
  }

  &__top {
    margin-bottom: 40px;

    @media only screen and (max-width: 1600px) {
      margin-bottom: 25px;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: 1fr 96px 1fr 96px 1fr;
    gap: 30px;

    @media only screen and (max-width: 1600px) {
      grid-template-columns: 1fr 70px 1fr 70px 1fr;
      gap: 20px;
    }

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr 50px 1fr 50px 1fr;
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__title {
    font-size: 32px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--primaryColor);
    text-align: center;
    max-width: 90%;
    margin: 0 auto;

    @media only screen and (max-width: 1600px) {
      font-size: 26px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  &__seperator {
    position: relative;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    align-self: center;
    // transform: scaleX(-1);

    .rotated {
      transform: rotate(180deg);
    }

    @media only screen and (max-width: 1600px) {
      width: 70px;
      height: 70px;
    }

    @media only screen and (max-width: 1024px) {
      width: 50px;
      height: 50px;
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      right: 100%;
      object-fit: contain;
      object-position: center;

      @media only screen and (max-width: 768px) {
        transform: rotate(90deg);
      }
    }
  }
}

.cardItem {
  &_1 {
    grid-column: 1 / span 1;

    @media only screen and (max-width: 768px) {
      grid-column: auto;
    }
  }

  &_2 {
    grid-column: 3 / span 1;

    @media only screen and (max-width: 768px) {
      grid-column: auto;
    }
  }

  &_3 {
    grid-column: 5 / span 1;

    @media only screen and (max-width: 768px) {
      grid-column: auto;
    }
  }

  &__figure {
    position: relative;
    width: 80px;
    height: 0;
    padding: 0 0 80px 0;
    overflow: hidden;
    margin: 20px auto;

    @media only screen and (max-width: 1600px) {
      width: 60px;
      padding: 0 0 60px 0;
      margin: 15px auto;
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__title {
    font-size: 30px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--white);
    text-align: center;
    margin-bottom: 5px;

    @media only screen and (max-width: 1600px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  &__subTitle {
    font-size: 20px;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 1.4px;
    color: var(--white);
    text-align: center;

    @media only screen and (max-width: 1600px) {
      font-size: 16px;
    }
  }

  &__tagLine {
    font-size: 25px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 1.4px;
    color: var(--white);
    text-align: center;

    @media only screen and (max-width: 1600px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}




.ecoSystem {
  width: 100%;
  max-width: 1156px;
  margin: 0 auto;

  &__heading {
    position: relative;
    text-align: center;
    border: 2px solid var(--primaryColor);
    border-radius: 25px;
    background-color: var(--primaryTextColor);
    z-index: 1;

    &::after {
      content: "";
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 100%;
      height: 2px;
      background-color: var(--secondaryColor);
      z-index: -1;

      @media only screen and (max-width: 445px) {
        content: none;
      }
    }

    h2 {
      position: relative;
      display: inline-block;
      margin: 0 auto;

      font-size: 40px;
      font-family: "Russo One", sans-serif;
      font-weight: 400;
      line-height: 1.2em;
      text-align: center;
      color: var(--primaryColor);
      padding: 8px 40px;
      background-color: var(--primaryTextColor);
      z-index: 1;

      @media only screen and (max-width: 1600px) {
        font-size: 30px;
        padding: 8px 30px;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 26px;
      }

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    padding-top: 45px;

    @media only screen and (max-width: 1600px) {
      gap: 50px;
    }

    @media only screen and (max-width: 1440px) {
      gap: 35px;
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.card2 {
  position: relative;

  padding: 45px 42px;
  border-radius: 10px;
  border: 3px solid var(--primaryColor);
  background-color: rgba($primaryTextColor, 0.5);
  backdrop-filter: blur(15px);

  @media only screen and (max-width: 1600px) {
    padding: 35px 35px;
  }

  @media only screen and (max-width: 1440px) {
    padding: 35px 30px;
  }

  @media only screen and (max-width: 768px) {
    padding: 25px 25px;
  }

  &__absoluteTop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;

    &__left {
      display: flex;
      align-items: center;
      margin: -6px -6px;

      @media only screen and (max-width: 1024px) {
        margin: -3px -3px;
      }

      & > * {
        padding: 6px 6px;

        @media only screen and (max-width: 1024px) {
          padding: 3px 3px;
        }
      }

      & img {
        display: inline-block;
        width: 48px;
        height: 48px;
        object-fit: contain;
        object-position: center;

        @media only screen and (max-width: 1600px) {
          width: 36px;
          height: 36px;
        }

        @media only screen and (max-width: 1024px) {
          width: 28px;
          height: 28px;
        }
      }
    }

    &__right {
      & > a {
        display: flex;
        align-items: center;

        font-size: 15px;
        font-family: "Barlow", sans-serif;
        font-weight: 600;
        line-height: 1.2em;
        letter-spacing: 1.4px;
        color: var(--primaryColor);

        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }

        & > img {
          display: inline-block;
          width: 10px;
          height: 10px;
          object-fit: contain;
          object-position: center;
          margin-right: 10px;

          @media only screen and (max-width: 1024px) {
            width: 8px;
            height: 8px;
            margin-right: 8px;
          }
        }

        & > p {
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;
          letter-spacing: inherit;
          color: inherit;
        }
      }
    }
  }

  &__figure {
    position: relative;
    width: 106px;
    height: 0;
    padding: 0 0 106px 0;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 35px;

    @media only screen and (max-width: 1600px) {
      width: 92px;
      padding: 0 0 92px 0;
      margin-bottom: 20px;
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__title {
    font-size: 25px;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--white);
    text-align: center;
    margin-bottom: 25px;

    @media only screen and (max-width: 1600px) {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  &__subTitle {
    font-size: 15px;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 1.4px;
    color: var(--white);
    text-align: center;
    margin-bottom: 40px;
    min-height: 1.2em;

    @media only screen and (max-width: 1600px) {
      font-size: 12px;
      margin-bottom: 25px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 10px;
    }

    @media only screen and (max-width: 380px) {
      font-size: 8px;
      font-weight: 400;
    }
  }

  &__gist {
    font-size: 22px;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 1.4px;
    color: var(--white);
    margin-bottom: 28px;

    @media only screen and (max-width: 1600px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  &__attributes {
    &__heading {
      font-size: 22px;
      font-family: "Barlow", sans-serif;
      font-weight: 700;
      line-height: 1.2em;
      letter-spacing: 1.4px;
      color: var(--white);
      margin-bottom: 5px;

      @media only screen and (max-width: 1600px) {
        font-size: 18px;
      }
    }

    &__item {
      font-size: 20px;
      font-family: "Barlow", sans-serif;
      line-height: 1.3em;
      letter-spacing: 1.4px;
      color: var(--white);

      @media only screen and (max-width: 1600px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 2em;
      }

      & .medium {
        font-weight: 500;
      }

      & .bold {
        font-weight: 700;
      }
    }
  }

  &__cta {
    margin-top: 77px;
    text-align: center;

    @media only screen and (max-width: 1600px) {
      margin-top: 40px;
    }

    & > button {
      @include buttonReset();
      background-color: transparent;
      margin: 0 auto;

      font-size: 18px;
      font-family: "Russo One", sans-serif;
      font-weight: 400;
      line-height: 1.2em;
      color: var(--primaryColor);
      text-align: center;
      padding: 15px 18px;
      min-width: 150px;

      border-radius: 11px;
      border: 3px solid;
      border-color: var(--primaryColor);
      box-shadow: 0 0 40px transparent;
      transition: color $transition-short linear,
        background-color $transition-short linear,
        box-shadow $transition-short linear;

      @media only screen and (max-width: 1600px) {
        font-size: 16px;
        padding: 12px 12px;
        border-width: 2px;
      }

      @media only screen and (max-width: 768px) {
        font-size: 12px;
        min-width: 126px;
      }

      &:hover {
        outline: none;
        color: var(--white);
        background-color: var(--primaryColor);
        box-shadow: 0 0 40px rgba($white, 0.2);
      }
    }
  }
}
