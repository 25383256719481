@import "../partials/theme";

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba($primaryColor, 1) rgba($darkGreyColor, 1);
}

/* Works on Chrome, Opera, Edge, and Safari */

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: $darkGreyColor;

    &-track {
        // border-radius: .5rem;
        background-color: rgba($darkGreyColor, 1);
    }

    &-thumb {
        height: 8rem;
        border-radius: .5rem;
        background: rgba($primaryColor, 1);
    }

    &-corner {
        background-color: $darkGreyColor;
    }
}