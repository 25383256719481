@import "../../assets/styles/partials/theme";
@import "../../assets/styles/partials/mixins";

.button {
  // Reset
  @include buttonReset();

  position: relative;
  font-size: 15px;
  font-family: "Russo One", sans-serif;
  font-weight: 400;
  line-height: 1.2em;
  color: var(--primaryColor);
  padding: 12px 20px;
  border: 2px solid var(--primaryColor);
  border-radius: 26px;
  background-color: transparent;
  overflow: hidden;

  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: color $transition-short linear;

  @media only screen and (max-width: 1600px) {
    font-size: 14px;
    padding: 12px 20px;
  }

  @media only screen and (max-width: 1440px) {
    font-size: 12px;
    padding: 10px 15px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 8px 15px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    padding: 12px 15px;
    border-radius: 11px;
  }

  &::before {
    content: "";
    display: block;
    border: none;
    outline: none;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);

    width: 100%;
    height: 0%;
    background-color: var(--primaryColor);
    z-index: 1;
    transition: height $transition-long ease;
  }

  &:hover {
    outline: none;
    cursor: pointer;
    color: var(--white);

    &::before {
      height: 380%;
    }
  }

  &__text {
    position: relative;
    z-index: 2;

    font-family: inherit;
    font-weight: bold;
    line-height: inherit;
    font-size: 16px;
    letter-spacing: 1px;
  }

  &__icon {
    position: relative;
    z-index: 2;
    width: 15px;
    height: 16px;
    margin-right: 10px;
    margin-left: 7px;

    @media only screen and (max-width: 1600px) {
      width: 12px;
      height: 13px;
    }

    @media only screen and (max-width: 1440px) {
      width: 10px;
      height: 11px;
      margin-left: 7px;
    }

    @media only screen and (max-width: 768px) {
      width: 12.8px;
      height: 14px;
      margin-right: 9px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

.footer {
  &.button {
    font-size: 12px;
    padding: 15px 20px;

    @media only screen and (max-width: 1600px) {
      font-size: 12px;
      padding: 12px 20px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 10px;
      padding: 8px 15px;
    }

    @media only screen and (max-width: 820px) {
      font-size: 10px;
      padding: 12px 15px;
      border-radius: 10px;
      margin-right: 13px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 8px;
      padding: 12px 15px;
      border-radius: 10px;
    }
  }

  .button {
    &__icon {
      width: 12px;
      height: 13px;

      @media only screen and (max-width: 1024px) {
        width: 10px;
        height: 11px;
      }

      @media only screen and (max-width: 768px) {
        width: 12px;
        height: 12px;
      }
    }
  }
}
