*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins";
  font-size: 16px;
  color: var(--white);
  background-color: var(--bodyColor);
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

// Container
.wrapper,
.container {
  position: relative;
  width: 100%;
  max-width: 1728px;
  margin: 0 auto;
}

// Max Container
.wrapper-fluid,
.container-fluid {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

// Section
.section {
  &:not(.no-padding) {
    padding-top: var(--gap);
    padding-bottom: var(--gap);
  }
  &.padding-bottom-none {
    padding-bottom: 0;
  }
  &.padding-top-none {
    padding-top: 0;
  }
}

// Headings
.heading-1 {
  font-size: 40px;
  font-family: "Russo One", sans-serif;
  font-weight: 400;
  line-height: 1.2em;
  color: var(--white);
  max-width: 90%;
  margin: 0 auto;

  @media only screen and (max-width: 1600px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 1440px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    // margin-left: 0px;
  }

  &.center {
    text-align: center;
  }

  &.fullWidth {
    max-width: 100%;
  }

  &.small {
    font-size: 24px;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    letter-spacing: 1.4px;

    @media only screen and (max-width: 1600px) {
      font-size: 24px;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 24px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}

.heading-2 {
  font-size: 50px;
  font-family: "Russo One", sans-serif;
  font-weight: 400;
  line-height: 1.2em;
  color: var(--white);
  max-width: 90%;
  margin: 0 auto;

  @media only screen and (max-width: 1600px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 1440px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }

  &.center {
    text-align: center;
  }

  &.fullWidth {
    max-width: 100%;
  }
}
