@import "../../assets/styles/partials/theme";

.InfiniteLoopBrandSlider {
  &__image {
    display: inline-block;
    position: relative;
    width: 180px;
    height: 0;
    padding: 0 0 calc(100% *281 / 677) 0;
    margin: auto;
    overflow: hidden;
    user-select: none;

    @media only screen and (max-width: 1600px) {
      width: 150px;
    }

    @media only screen and (max-width: 1024px) {
      width: 120px;
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      user-select: none;
    }
  }

  .mySwiper > div {
    align-items: center;
    transition-timing-function: linear !important;
  }

  .mySwiper > div > div {
    width: 180px !important;

    @media only screen and (max-width: 1600px) {
      width: 150px !important;
    }

    @media only screen and (max-width: 1024px) {
      width: 120px !important;
    }
  }
}
