@import "../../assets/styles/partials/theme";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: var(--primaryTextColor);

  &__wrapper {
    height: 100%;
    width: 100%;
    max-width: 1728px;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__animation {
    width: 285px;
    user-select: none;

    & > div {
      position: relative;
      width: 100% !important;
      height: 0 !important;
      padding: 0 0 100% 0;
      user-select: none;

      & svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        object-position: center;
        user-select: none;
      }
    }
  }
}
