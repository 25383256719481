@import "../assets/styles/partials/theme";

.layout {
  background-color: var(--primaryTextColor);
  background-image: radial-gradient(
    52.37% 52.37% at 36.11% 30.83%,
    #3c3f42 0%,
    var(--primaryTextColor) 100%
  );

  &__wrapper {
    width: 100%;
  }

  &__mainContent {
    min-height: calc(100vh - 1px); // 100vh - header height

    &--autoHeight {
      min-height: auto;
    }
  }

  &__banner {
    font-family: "Russo One";
    width: 100%;
    background: #f5f111;
    color: black;
    text-align: center;
    padding: 0.5em;
    a {
      text-decoration: underline;
    }
  }
}
