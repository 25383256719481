// Body
$bodyColor: #242426;

// Theme 
$primaryColor: #F0CE0E;
$secondaryColor: #01D2D4;
$tertiaryColor: #CFAE42;

//Transitions
$transition-short: 0.2s;
$transition: 0.4s;
$transition-long: 0.6s;

// Spacing & Gaps
$gap: 25px;

// Miscellaneous
$primaryTextColor: #181B1F;
$greyColor: #bbbbbb;
$lightGreyColor: #f3f3f3;
$darkGreyColor: #787878;
$borderColor: #e8e8e8;

// Defaults
$success: #2e7d32 !default;
$info: #0288d1 !default;
$warning: #ed6c02 !default;
$danger: #eb0014 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "bodyColor": $bodyColor,
    "primaryColor": $primaryColor,
    "secondaryColor": $secondaryColor,
    "tertiaryColor": $tertiaryColor,
    "primaryTextColor": $primaryTextColor,
    "greyColor": $greyColor,
    "lightGreyColor": $lightGreyColor,
    "darkGreyColor": $darkGreyColor,
    "borderColor": $borderColor,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
  ),
  $theme-colors
);

/* ================================
      Grey Shades Theme Color
=================================== */

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "white": $white,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "gray-800": $gray-800,
    "gray-900": $gray-900,
    "black": $black,
  ),
  $grays
);

// -----------------------------------------/

/* ================================
      Social Media Theme Color
=================================== */

// $facebook: #1877f2; // Facebook Brand Color
$facebook: #4267b2; // Facebook Log In Button Color
$twitter: #1da1f2;
$instagram: #dd2a7b;
$linkedin: #0a66c2;
$google-plus: #dd4d40;
$pinterest: #e56262;
$youtube: #d8362a;

$socialMediaThemeColor: () !default;
$socialMediaThemeColor: map-merge(
  (
    "facebook": $facebook,
    "twitter": $twitter,
    "instagram": $instagram,
    "linkedin": $linkedin,
    "google-plus": $google-plus,
    "pinterest": $pinterest,
    "youtube": $youtube,
  ),
  $socialMediaThemeColor
);

// -----------------------------------------/
